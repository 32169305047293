import React from "react";
import { PropsWithChildren } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { Outlet } from "react-router-dom";
import Hero from "../components/hero";

export default function MainLayout(props: PropsWithChildren) {
  return (
    <>
      <Navbar />
      <Hero />
      <Outlet />
      <Footer />
    </>
  );
}
