import React from "react";
import FrontImage from "../components/front-image";
import Vision from "../components/vision";
import Services from "../components/services";

export default function home() {
  return (
    <>
      <FrontImage />
      <Vision />
      <Services />
    </>
  );
}
