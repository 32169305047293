import React from "react";
import ReactDOM from "react-dom/client";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import reportWebVitals from "./reportWebVitals";
import "./Assets/sass/main.scss";
import Vision from "./components/vision";
import Services from "./components/services";
import Footer from "./components/footer";
import { Outlet } from "react-router-dom";
import RouterConfig from "./router.config";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterConfig />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
