import React from "react";

export default function footer() {
  return (
    <div className="footer">
      <a href="mailto:info@resourcedatasynergyltd.com">
        info@resourcedatasynergyltd.com
      </a>

      <p>
        © 2023 Resourcedata Synergy Limited <br />
        All Right Reserved
      </p>
      <p></p>
    </div>
  );
}
