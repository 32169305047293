import React from "react";

export default function vision() {
  return (
    <div className="vision">
      <div className="main-line"></div>
      <h2>Vision</h2>
      <p>
        To offer value-added decision support data for the demanding performance
        of today's cost efficient resource manager.
      </p>
      <h2>Mission Statement</h2>
      <ul>
        <li>
          <span>Focus</span>- Optimized customer content specific-deliverables
          within budget and timeline
        </li>
        <li>
          <span>Value</span>- Value engineering through synchronized and
          seamless workflow.
        </li>
        <li>
          <span>Integrity</span>- Earned and driven by robust policy of
          interaction and mentoring at all level of experience
        </li>
      </ul>
      <div className="main-line"></div>
    </div>
  );
}
