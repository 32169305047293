import React from "react";
import heroImage from "../Assets/static/images/Front-Image.webp";

export default function about() {
  return (
    <div className="about">
      <h2>About Us</h2>
      <div className="hero-image">
        <img src={heroImage} alt="" />
      </div>
      <div className="line"></div>
      <p>
        <strong>RESOURCEDATA SYNERGY LIMITED (RDS)</strong> is a team of
        surveyors and engineers founded upon the synergy and comprehensive
        background of the principals.
        <br />
        <br />
        RDS employ established systems and procedures with comprehensive state
        of the art field proven technology from desk office to acquisition and
        processing of digital geospatial information in a collaborative
        partnership that helps the end user optimize content
        specific-deliverables and redefine performance and benefits in their
        projects.
        <br />
        <br /> ​ The vision and commitment to implementing and maintaining
        Quality Assurance Plan (QAP) is not only our inspiration but our
        obligation, driven by robust internalized policy of constant interaction
        and mentoring at all levels of experience thus ensuring that all
        activities are planned, controlled, and verified for compliance with
        applicable standard and requirements for enhanced customer satisfaction
        and in sync with HSE.
        <br />
        <br /> ​ A unique feature of the company is its broad based
        multi-discipline structure and competences in offering fit for purposed
        location data synchronized in seamless workflow for efficient sharing of
        critical enterprise information in time-sensitive project across various
        industry disciplines.
        <br />
        <br /> ​ Motto: Engineering the future with Survey-Edge ​ <br />
        <br />
        Signed
      </p>
      <br />
      <p>
        <strong>
          Surv. Francis Opute<br></br> (MD and CEO)
        </strong>
      </p>
    </div>
  );
}
