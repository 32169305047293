import React from "react";
import Logo from "../Assets/static/images/New_Logo_JPG.webp";
import Logotext from "../Assets/static/images/Resource_Company_JPG.webp";

export default function navbar() {
  return (
    <div className="navbar">
      <div className="logo flex">
        <img className="logo-image" src={Logo} alt="" />
        <img className="logo-image" src={Logotext} alt="" />
      </div>
    </div>
  );
}
