import React from "react";
import heroImage from "../Assets/static/images/Front-Image.webp";

export default function FrontImage() {
  return (
    <div>
      <div>
        <div className="hero-image">
          <div className="contact-details">
            <h2>CALL US NOW for any of our services</h2>
            <h2>+2348033784560, +2348054989309</h2>
          </div>
          <img src={heroImage} alt="" />
        </div>
        <div className="line"></div>
      </div>
    </div>
  );
}
