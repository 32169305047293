import React from "react";
import heroImage from "../Assets/static/images/Front-Image.webp";

export default function client() {
  return (
    <div className="client">
      <h1>Our Clients</h1>
      <div className="line"></div>
      <ul>
        <li>Federal Ministry of Works and Housing</li>
        <li>Federal Ministry of Lands Housing and Urban Development</li>
        <li>Ministry of Works, Delta State</li>
        <li>National Maritime Administration and Safety Agency</li>
        <li>Seplat Petroleum Development Company PLC</li>
        <li>Platform Petroleum Limited</li>
        <li>Midwestern Oil and Gas Company PLC</li>
        <li>Panocean Oil Corporation (Nigeria)</li>
        <li>Chorus Energy</li>
        <li>Septa Energy</li>
        <li>Hensteel Engineering Construction Service Limited</li>
        <li>Resort Savings and Loads Limited</li>
        <li>Corporate Hires Limited</li>
        <p>etc</p>
      </ul>
      <div className="line"></div>
    </div>
  );
}
