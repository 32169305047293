import React from "react";
import Map from "../Assets/static/images/resourcedata-map.png";

export default function contact() {
  return (
    <div className="contact-us">
      <div className="contact-wrap">
        <div>
          <h1>You can visit or talk to us</h1>
          <h2>ResourceData Synergy Limited</h2>
          <p>42 Hospital Road.</p>
          <p>By Seat Of Wisdom School</p>
          <p>Ekpan Warri</p>
          <p>Nigeria</p>
          <p>Mobile +234(0)803-3784560, 8028345741, 805-4989309</p>
          <p>
            E-mail resourcedydx@gmail.com <span> RC 129008</span>
          </p>
        </div>

        <div>
          <input placeholder="Name" type="text" />
          <input placeholder="Email" type="text" />
          <input placeholder="Phone" type="text" />
          <textarea placeholder="Type your message here..." />
          <button>Submit</button>
        </div>
      </div>
      <div className="map">
        <a href="https://www.google.com/maps/dir/6.6549018,3.3330647/Ekpan+Hospital,+198+Jakpa+Rd,+Tori+330102,+Warri,+Delta/@6.2169015,1.9115646,7z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x1041ad176b7de337:0x7c6b2f6c0315cd2c!2m2!1d5.7546716!2d5.5640636?entry=ttu">
          <img src={Map} alt="" />
        </a>
      </div>
    </div>
  );
}
