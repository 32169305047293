// import React from "react";
// import heroImage from "../Assets/static/images/Front-Image.webp";

// export default function hero() {
//   return (
//     <div className="hero">
//       <ol className="hero-nav">
//         <a className="index" href="">
//           <li>Home</li>
//         </a>
//         <div className="line"></div>
//         <a href="">
//           <li>About Us</li>
//         </a>
//         <div className="line"></div>
//         <a href="">
//           <li>Contact</li>
//         </a>
//         <div className="line"></div>
//         <a href="">
//           <li>Our clients</li>
//         </a>
//       </ol>

//       <div className="hero-image">
//         <div className="contact-details">
//           <h2>CALL US NOW for any of our services</h2>
//           <h2>+2348033784560, +2348054989309</h2>
//         </div>
//         <img src={heroImage} alt="" />
//       </div>
//       <div className="line"></div>
//     </div>
//   );
// }

import React, { useState } from "react";
import heroImage from "../Assets/static/images/Front-Image.webp";
import Heroo from "../router.config";
import { Link } from "react-router-dom";

export default function Hero() {
  const [activeItem, setActiveItem] = useState("Home");

  const handleItemClick = (item: string) => {
    setActiveItem(item);
  };

  return (
    <div className="hero">
      <ol className="hero-nav">
        <Link
          to="/"
          className={`index ${activeItem === "Home" ? "active" : ""}`}
          onClick={() => handleItemClick("Home")}
        >
          <li>Home</li>
        </Link>
        <div className="line"></div>
        <Link
          to="/about"
          className={`${activeItem === "About Us" ? "active" : ""}`}
          onClick={() => handleItemClick("About Us")}
        >
          <li>About Us</li>
        </Link>
        <div className="line"></div>
        <Link
          to="contact"
          className={`${activeItem === "Contact" ? "active" : ""}`}
          onClick={() => handleItemClick("Contact")}
        >
          <li>Contact</li>
        </Link>
        <div className="line"></div>
        <Link
          to="/client"
          className={`${activeItem === "Our Clients" ? "active" : ""}`}
          onClick={() => handleItemClick("Our Clients")}
        >
          <li>Our clients</li>
        </Link>
      </ol>
    </div>
  );
}
