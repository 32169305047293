import React from "react";

export default function services() {
  return (
    <div className="services">
      <h1>Our services</h1>
      <div className="flex">
        <div className="box">
          <div className="heading">
            <h3>Brown Water and Shallow-Marine Surveys</h3>
          </div>
          <ul>
            <li>Erosion & Flood Control Mapping</li>
            <li>Canalisation and Shoreline Protection Surveys</li>
            <li>Storm-water embankment Survey</li>
            <li>Dam and Reservoir Siltation surveys</li>
            <li>Beach profile and Habitat restoration surveys</li>
            <li>Pre and post-dredge payment surveys</li>
            <li>Subsidence and Settlement monitoring survey</li>
            <li>Navigation and channel obstruction survey</li>
            <li>Regulatory Ordered/Compliance Mapping</li>
          </ul>
        </div>
        <div className="box">
          <div className="heading">
            <h3>Engineering and Oil Industry Surveys</h3>
          </div>
          <ul>
            <li>Preliminary and Full Preparation Survey</li>
            <li>Pipeline Intelligent Pig / Linalog Survey</li>
            <li>Route Alignment / Pipeline Right-of-Way Survey</li>
            <li>Tank Ovality-Check & Tall Structure Monitoring Survey</li>
            <li>Project Control-Points Network and Extension </li>
            <li>Construction and As-Built Surveys</li>
            <li>Litigation & Claims Surveys</li>
            <li>Boundary & Cadastral Survey</li>
            <li>Geotechnical and Soil Investigation Services</li>
            <li>Environmental Impact and Evaluation Survey</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
